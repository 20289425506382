var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('serviceQuotation.pageTitle')}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('addNew'))+" ")]),_c('div',{staticClass:"tw-justify-end tw-space-x-3 tw-flex"},[_c('div',{staticClass:"tw-w-52"}),_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('serviceTicket.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ts-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('filter')),expression:"$t('filter')"}],on:{"click":function($event){$event.preventDefault();_vm.visible = true}}},[_c('i',{staticClass:"fa fa-filter"})])],1)],1)]),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"branch",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.branch ? row.branch.branch_name_en : '')+" ")]}},{key:"ticket_no",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.service_ticket ? row.service_ticket.ticket_no : '')+" ")]}},{key:"customer_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.service_ticket.customer ? row.service_ticket.customer.customer_name : '')+" ")]}},{key:"contact_number",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.service_ticket.customer ? row.service_ticket.customer.contact_number : '')+" ")]}},{key:"grand_total",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.service_ticket.grand_total ? row.service_ticket.grand_total : '')+" ")]}},{key:"served_by",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.service_ticket.served_by_name ? row.service_ticket.served_by_name.employee ? row.service_ticket.served_by_name.employee .employee_name_en : '' : '')+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.ticket_status == 1)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1):_vm._e(),(row.ticket_status == 1)?_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.deleteRecord(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)]):_vm._e()]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),_c('Drawer',{attrs:{"title":_vm.$t('filter'),"closable":false,"value":_vm.visible,"width":"300px"},on:{"on-close":function () { return (_vm.visible = false); }}},[_c('div',{staticClass:"row tw-mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',{},[_vm._v(_vm._s(_vm.$t('warehouse.branch')))]),_c('Select',{staticClass:"tw-w-full",attrs:{"filterable":"","multiple":"","max-tag-count":1},model:{value:(_vm.branch_id),callback:function ($$v) {_vm.branch_id=$$v},expression:"branch_id"}},_vm._l((_vm.branchs),function(branch){return _c('Option',{key:branch.value,attrs:{"value":branch.value}},[_vm._v(" "+_vm._s(branch.label)+" ")])}),1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }