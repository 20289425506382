<template>
    <div>
        <ts-page-title :title="$t('serviceQuotation.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-between">
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                    <div class="tw-justify-end tw-space-x-3 tw-flex">
                        <div class="tw-w-52">
                            <!-- <Select
                                class="tw-w-full"
                                v-model="ticket_status"
                                multiple
                                :max-tag-count="1"
                                :placeholder="$t('all')"
                                @on-change="fetchData"
                            >
                                <Option
                                    v-for="ticketStatus in ticketStatusList"
                                    :value="ticketStatus.ticket_status_id"
                                    :key="ticketStatus.ticket_status_id"
                                    :label="ticketStatus.ticket_status"
                                >
                                    {{ ticketStatus.ticket_status }}
                                </Option>
                            </Select> -->
                        </div>
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('serviceTicket.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                        <ts-button
                            v-tooltip="$t('filter')"
                            @click.prevent="visible = true"
                        >
                            <i class="fa fa-filter"></i>
                        </ts-button>
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="ticket_no">
                    {{ row.service_ticket ? row.service_ticket.ticket_no : '' }}
                </template>
                <template slot-scope="{ row }" slot="customer_name">
                    {{
                        row.service_ticket.customer
                            ? row.service_ticket.customer.customer_name
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="contact_number">
                    {{
                        row.service_ticket.customer
                            ? row.service_ticket.customer.contact_number
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="grand_total">
                    {{
                        row.service_ticket.grand_total
                            ? row.service_ticket.grand_total
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="served_by">
                    {{
                        row.service_ticket.served_by_name
                            ? row.service_ticket.served_by_name.employee
                                ? row.service_ticket.served_by_name.employee
                                      .employee_name_en
                                : ''
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        v-if="row.ticket_status == 1"
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="edit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        v-if="row.ticket_status == 1"
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="deleteRecord(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- <a-modal
                :title="$t('pdf')"
                width="50%"
                v-model="show_pdf"
                :footer="null"
            >
                <ts-preview-pdf v-model="waiting" :src="src" />
            </a-modal> -->
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="row tw-mb-3">
                <div class="col-md-12">
                    <label class="">{{ $t('warehouse.branch') }}</label>
                    <Select
                        v-model="branch_id"
                        class="tw-w-full"
                        filterable
                        multiple
                        :max-tag-count="1"
                    >
                        <Option
                            v-for="branch in branchs"
                            :value="branch.value"
                            :key="branch.value"
                        >
                            {{ branch.label }}
                        </Option>
                    </Select>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Errors } from 'form-backend-validation'
import expandRow from './table-expand.vue'
import { debounce } from 'lodash'

export default {
    name: 'serviceQuotation',
    data () {
        return {
            loading: false,
            errors: new Errors(),
            show_confirm_form: false,
            waiting: false,
            show_pdf: false,
            src: '',
            branchs: [],
            width: 'width: 300px',
            visible: false,
            model: {
                deleted_reason: null
            }
        }
    },
    created () {
        this.fetchFilterData()
    },
    computed: {
        ...mapState('customerService/serviceQuotation', [
            'resources',
            'pagination',
            'formViewDatas'
        ]),
        search: {
            get () {
                return this.$store.state.customerService.serviceQuotation.search
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/serviceQuotation/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'customerService/serviceQuotation/RESET_CURRENT_PAGE'
                )
            }
        },
        branch_id: {
            get () {
                return this.$store.state.customerService.serviceQuotation
                    .branch_id
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/serviceQuotation/SET_BRANCH_ID',
                    newValue
                )
            }
        },
        columns () {
            return [
                {
                    type: 'expand',
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        })
                    },
                    align: 'center',
                    width: 50
                },
                {
                    title: this.$t('serviceQuotation.branch'),
                    slot: 'branch',
                    minWidth: 120,
                    sortable: true
                },
                {
                    title: this.$t('serviceQuotation.quotationNo'),
                    minWidth: 130,
                    key: 'quotation_no'
                },
                {
                    title: this.$t('serviceQuotation.quotationDate'),
                    minWidth: 180,
                    key: 'quotation_date'
                },
                {
                    title: this.$t('serviceQuotation.ticketNo'),
                    minWidth: 120,
                    slot: 'ticket_no'
                },
                {
                    title: this.$t('serviceQuotation.customer'),
                    slot: 'customer_name',
                    minWidth: 150,
                    sortable: true
                },
                {
                    title: this.$t('serviceQuotation.customerPhone'),
                    slot: 'contact_number',
                    minWidth: 160,
                    sortable: true
                },
                {
                    title: this.$t('serviceQuotation.amount'),
                    slot: 'grand_total',
                    minWidth: 160,
                    sortable: true
                },
                {
                    title: this.$t('serviceQuotation.servedBy'),
                    slot: 'served_by',
                    minWidth: 160,
                    sortable: true
                },
                {
                    title: this.$t('serviceQuotation.remarks'),
                    key: 'remarks',
                    minWidth: 100
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 90
                }
            ]
        }
    },
    methods: {
        ...mapActions('customerService/serviceQuotation', ['getFormViewModel']),
        fetchFilterData () {
            this.getFormViewModel({
                index: 'serviceTicketStatus',
                params: {
                    fnName: 'serviceTicketStatus'
                }
            })
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssignedForFilter')
                .then(response => {
                    this.branchs = response.data.map(el => ({
                        value: el.branch_id,
                        label: el.branch_name_en
                    }))
                    this.branch_id = [
                        this.$store.state.authUser.user.default_branch.branch_id
                    ]
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        addNew () {
            this.$router.push({ name: 'service-quotation-create' })
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('customerService/serviceQuotation/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        edit (record) {
            this.$store.commit(
                'customerService/serviceQuotation/SET_EDIT_DATA',
                record
            )
            this.$router.push({
                name: 'service-quotation-edit',
                params: { id: record.quotation_id }
            })
        },
        deleteRecord (record) {
            record._deleting = true
            this.$store
                .dispatch(
                    'customerService/serviceTicket/destroy',
                    record.service_ticket_id
                )
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        reprintReport (record, template) {
            this.show_pdf = true
            this.waiting = true
            this.src = ''
            this.$store
                .dispatch('customerService/serviceQuotation/reprint', {
                    quotation_id: record.quotation_id,
                    template: template
                })
                .then(response => {
                    this.src = response.url
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'SERVICE QUOTATION',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500),
        branch_id: debounce(function () {
            this.fetchData(this.search)
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('customerService/serviceQuotation/RESET_STATE')
        next()
    }
}
</script>
